import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const ServiceLevelAgreement = () => {
  return (
    <Layout>
      <Seo
        title="Service Level Agreement | Bridge"
        description="Bridge's service level agreement for the provisioning of IT services required to support and sustain the patient portal software product."
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1>Service Level Agreement</h1>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h2>1.0 Agreement Overview</h2>
              <p>
                This agreement represents a Service Level Agreement
                (&apos;SLA&apos;) between Bridge Patient Portal (“COMPANY”) and
                its customer (“CLIENT”) for the provisioning of IT services
                required to support and sustain the Patient Portal software
                (“Product”). This SLA is only valid as a supplementary agreement
                to an active license or services agreement between COMPANY and
                CLIENT.
              </p>
              <p>
                This SLA remains valid until superseded by a revised agreement
                mutually endorsed by the stakeholders.
              </p>
              <h2>2.0 Goals & Objectives</h2>
              <p>
                The purpose of this SLA is to ensure that the proper elements
                and commitments are in place to provide consistent IT service
                support and delivery to the CLIENT by the COMPANY.
              </p>
              <p>The objectives of this SLA are to:</p>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Provide clear reference to service ownership, accountability,
                  roles and/or responsibilities
                </li>
                <li>
                  Present a clear, concise and measurable description of service
                  provision to the CLIENT
                </li>
                <li>
                  Match perceptions of expected service provision with actual
                  service support & delivery
                </li>
              </ul>
              <h2>3.0 Definitions</h2>
              <p>
                <strong>“Downtime”</strong>, is defined as a period during which
                the Product is not functional or does work that is outside of
                maintenance services. This does not include a situation where a
                single feature or function that is not crucial to the operation
                of the Portal is not functioning, but a larger issue which
                renders the Product unusable.
              </p>
              <p>
                <strong>“Uptime”</strong>, is defined as a period during which
                the Product is operating normally, and while this may include
                bugs, the bugs are not preventing users from using the system
                for most of its primary functions.
              </p>
              <h2>4.0 Service Agreement</h2>
              <p>
                The following detailed service parameters are the responsibility
                of the COMPANY in the ongoing support of this SLA.
              </p>
              <h3>4.1 Service Scope</h3>
              <p>
                This SLA applies only to the Product and Professional Services
                related to the Product, described in the SLA or applicable
                Statement of Work. This SLA does not apply to any software,
                services, or other parts of an information technology system
                that are not purchased from or managed by COMPANY.
              </p>
              <p>
                For CLIENT-hosted environments or COMPANY cloud-hosted
                environments dependent on CLIENT-hosted resources where the
                CLIENT-hosted resource is considered to be the cause of the
                outage, COMPANY will provide a reasonable amount of support to
                attempt to identify and resolve any cloud-related issues or
                outages. Reasonable amount of support is defined as up to two
                (2) hours of support (per server outage) to address the issue,
                after which a mutually agreed upon plan will be implemented. For
                COMPANY-hosted cloud environments, COMPANY will resolve issues
                with no limit to the effort or time required to resolve the
                issue.
              </p>
              <p>
                COMPANY will rectify service issues with the Product, except
                where:
              </p>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  The issue has been caused by CLIENT&apos;s use of the Product
                  in a manner that is contrary to COMPANY Training, Knowledge
                  Base, or any other instructions issued by COMPANY
                </li>
                <li>
                  The issue has been caused by CLIENT&apos;s use of the Product
                  in a manner that is contrary to industry standard
                  cybersecurity safety measures and best practices
                </li>
                <li>
                  CLIENT has prevented COMPANY from performing maintenance on
                  the Product or has been unresponsive or unwilling to update
                  the Product to a COMPANY-recommended new stable version of the
                  Product within a nine (9) month period from the release of the
                  stable version of the Product
                </li>
                <li>The issue has been caused by Third Party Products</li>
              </ul>
              <p>The following Services are included in this SLA:</p>
              <ul className="h-clear-list h-bullet-list">
                <li>Manned telephone support</li>
                <li>Monitored email support</li>
                <li>Support desk / ticket system</li>
                <li>Remote assistance using a Remote Desktop Protocol</li>
              </ul>
              <h2>5.0 Responsibilities</h2>
              <h3>5.1 Client Responsibilities</h3>
              <p>
                CLIENT responsibilities and/or requirements in support of this
                SLA include:
              </p>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Use the Product as intended under the Client&apos;s License
                  Agreement and this SLA
                </li>
                <li>
                  Payment for all support and maintenance costs according to the
                  Client&apos;s contracted hourly rate
                </li>
                <li>
                  In case of a high priority alert, ensure the availability of a
                  sufficient number of skilled Client employees to cooperate
                  with the Company
                </li>
                <li>
                  Reasonable availability of Client representative(s) when
                  resolving a service related incident or request
                </li>
                <li>
                  Notify Company of issues or problems in a timely and
                  descriptive/clear manner
                </li>
              </ul>
              <h3>5.2 COMPANY Responsibilities</h3>
              <p>
                COMPANY responsibilities and/or requirements in support of this
                SLA include:
              </p>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Meeting response times associated with service related
                  incidents
                </li>
                <li>
                  Appropriate notification to Client for all scheduled
                  maintenance
                </li>
                <li>
                  Best effort in diagnosis and repair of incident(s) including
                  critical decision making in emergency situations
                </li>
                <li>
                  Maintain clear and timely communication with CLIENT at all
                  times
                </li>
              </ul>
              <h3>5.3 Exceptions</h3>
              <p>
                CLIENT shall not receive any credit under the SLA in connection
                with any failure or deficiency of website or email availability
                caused by or associated with:
              </p>
              <ol type="A">
                <li>
                  Circumstances beyond COMPANY&apos;s reasonable control and
                  where COMPANY has implemented industry best practices to
                  prevent such issues, including, without limitation, acts of
                  any governmental body, war, insurrection, sabotage, armed
                  conflict, embargo, fire, flood, strike or other labor
                  disturbance, interruption of or delay in transportation,
                  unavailability of or interruption or delay in
                  telecommunications or third party services, virus attacks or
                  hackers, failure of third party software (including, without
                  limitation, e-commerce software, payment gateways, chat,
                  statistics or free scripts) or inability to obtain raw
                  materials, supplies, or power used in or equipment needed for
                  provision of this SLA;
                </li>
                <li>
                  Scheduled maintenance and emergency maintenance and upgrades
                  (Note: Every effort will be made to keep downtime to a minimum
                  during maintenance periods and, when possible, COMPANY will
                  notify CLIENT in advance of the expected downtime. CLIENT will
                  not be billed hourly for these maintenance periods, but these
                  maintenance periods are not eligible for SLA credits);
                </li>
                <li>DNS issues outside the direct control of COMPANY;</li>
                <li>
                  CLIENT&apos;s acts or omissions (including acts or omissions
                  of others engaged or authorized by the CLIENT), including,
                  without limitation, custom scripting or coding (e.g., CGI,
                  Perl, HTML, PHP, Python, Javascript, Bash, etc), any
                  negligence, willful misconduct, or use of the Services in
                  breach of this SLA;
                </li>
                <li>DNS (Domain Name Server) Propagation;</li>
                <li>
                  Outages elsewhere on the Internet that hinder access to your
                  account. COMPANY is not responsible for browser or DNS caching
                  that may make your site appear inaccessible when others can
                  still access it. COMPANY will guarantee only those areas
                  considered under the control of COMPANY.
                </li>
                <li>
                  Issues with email CLIENT&apos;s SMTP and/or business email
                  service
                </li>
              </ol>
              <h2>6.0 Service Management</h2>
              <p>
                Effective support of in-scope services is a result of
                maintaining consistent service levels. The following sections
                provide relevant details on service availability, monitoring of
                in-scope services and related components.
              </p>
              <h3>6.1 Support Availability</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Regular business hours are considered to be from 9.00 am until
                  5.00 pm Eastern Standard Time, Monday to Friday, except
                  Federal U.S. Holidays and observances as defined by the U.S.
                  Government.
                </li>
                <li>
                  Telephone support: will be conducted during business hours.
                  Direct contact information will be provided for telephone
                  support outside of business hours. However, no action will be
                  guaranteed until the next working day.
                </li>
                <li>
                  Email support and Support Desk: will be monitored during
                  business hours. Any email received outside office hours will
                  be collected, and best efforts will be made to respond to the
                  client&apos;s request. However, no action will be guaranteed
                  until the next working day.
                </li>
                <li>
                  Support desk / ticket system: CLIENTs provided this option are
                  encouraged to submit a ticket, no matter if during or after
                  office hours, via the support desk.
                </li>
              </ul>
              <h3>6.2 Service Availability</h3>
              <p>
                The COMPANY guarantees 99.5% Uptime each month 24 hours a day 7
                days a week (“Agreed Hours of Service”). Uptime is measured
                based on the monthly average of availability, rounded down to
                the nearest minute, and calculated as follows:
              </p>
              <p>
                Uptime% = ((Agreed Hours of Service - Hours of Downtime) /
                (Agreed Hours of Service)) x 100%
              </p>
              <h3>6.3 Service Credits</h3>
              <p>
                Should Uptime fall below the SLA agreed above in any calendar
                month, the COMPANY will compensate CLIENT in the form of Service
                Credits, which are calculated as follows:
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th scope="col">Cloud</th>
                    <th scope="col">Condition</th>
                    <th scope="col">Calculation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>SaaS</strong>
                    </td>
                    <td>Uptime ˂ 98%</td>
                    <td>100% of the Monthly Hosting Fee</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>SaaS</strong>
                    </td>
                    <td>Uptime ≥ 98% and less than 99.5%</td>
                    <td>
                      ((99.5% - Uptime%) / 0.9%) x Monthly Hosting Fee x 50%
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p>
                To apply for a Service Credit under this SLA, CLIENT must submit
                a request to your Support contact by email, within 30 days of
                the end of the applicable month with the subject line “SLA
                Service Credit”. The request must include the dates and times of
                the Downtime for which Service Credit is being requested, and
                any additional documentation that demonstrates the claimed
                Downtime. Service Credits are the exclusive remedy for the
                COMPANY&apos;s failure to meet its Uptime guarantee and no other
                or additional types of damages can be claimed, including breach
                of warranty. In the event there are no new invoices to be
                issued, the COMPANY will pay out the Service Credit to CLIENT
                directly.
              </p>
              <h3>6.4 Service Guarantees for Client-Hosted Environments</h3>
              <p>
                For systems hosted by CLIENT or systems hosted by COMPANY where
                the issue is caused by a CLIENT-hosted system, COMPANY will
                respond to the service issue in the response time listed above,
                but can make no guarantee to resolve the issue in any defined
                amount of time nor will COMPANY provide any service credits or
                refunds. COMPANY will make every effort to respond to all
                service requests in a timely manner.
              </p>
              <p>
                Remote assistance to CLIENT&apos;s systems will be provided
                in-line with the above timescales dependent on the priority of
                the support request.
              </p>
              <h3>6.5 Service Requests</h3>
              <p>
                In support of services outlined in this SLA, the COMPANY will
                respond to service related incidents and/or requests submitted
                by the CLIENT within the following time frames. Resolution times
                are subject to the complexity and nature of the service request.
                Once responded to, COMPANY will make a best effort to resolve
                any outstanding issue(s) as quickly as possible, including
                performing such resolution outside of business hours.
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Classification</th>
                    <th>Description</th>
                    <th>Response Time*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Critical</td>
                    <td>
                      Critical Business Impact-Problem or issue that renders
                      your Patient Portal unusable (ie: offline)
                    </td>
                    <td>
                      &lt; 2 hours (during business hours) &lt; 4 hours (during
                      non-business hours)
                    </td>
                  </tr>
                  <tr>
                    <td>High</td>
                    <td>
                      Significant Business Impact-Problem or issue that DOES
                      impact the operation of your Patient Portal or email but
                      DOES NOT render it unusable (i.e. slow server response
                      time).
                    </td>
                    <td>
                      &lt; 3 hours (during business hours) &lt; 6 hours (non
                      business hours)
                    </td>
                  </tr>
                  <tr>
                    <td>Low</td>
                    <td>
                      Minimal Business Impact-Problem or issue that DOES NOT
                      impact the operation of your Patient Portal or email (i.e.
                      maintenance requests).
                    </td>
                    <td>&lt; 48 hours (during business hours)</td>
                  </tr>
                </tbody>
              </Table>
              <h4>6.5.1 Support Escalation Policy</h4>
              <p>
                If the normal support process does not produce the desired
                results, or if the problem has changed in priority, the problem
                can be escalated as follows:
              </p>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  First contact the representative who is working on the problem
                  and request that the priority of the problem be escalated.
                </li>
                <li>
                  You may contact a the representative&apos;s manager or other
                  members of the COMPANY&apos;s IT team
                </li>
              </ul>
              <h4>6.5.2 Emergency Situation</h4>
              <ol type="A">
                <li>
                  Any work performed or calls handled outside regular business
                  hours for CLIENT for a problem not deemed to be the fault of
                  COMPANY and escalated as an emergency by CLIENT or deemed an
                  emergency by COMPANY may be billed at COMPANY&apos;s emergency
                  rate, at the discretion of COMPANY. Bill rate for emergency
                  services, unless otherwise contracted incurs a surcharge of
                  50% of the CLIENT&apos;s hourly rate, as defined in the
                  CLIENT&apos;s license or service agreement.
                </li>
                <li>
                  Services that are in need of an immediate resolution,
                  including but not limited to server failure, software failure,
                  e-PHI risk, that are not related to an existing project or
                  approved work/change order, may be completed without
                  CLIENT&apos;s approval.
                </li>
                <li>
                  CLIENT will be provided with an emergency email address and
                  phone number, which will be forwarded to multiple parties in
                  COMPANY&apos;s organization.
                </li>
              </ol>
              <p>
                CLIENT will be promptly notified in the event of an emergency
                situation, to the extent feasible, and the COMPANY will make
                best efforts to identify such circumstances in order to ensure
                the best possible service.
              </p>
              <h4>6.5.3 Critical and Emergency Service Requests</h4>
              <p>
                CLIENT is instructed to initiate all critical and emergency
                support requests by sending an email to
                emergency@bridgepatientportal.com before creating a service desk
                ticket or contacting one&apos;s account and/or project manager.
                After sending an email to the emergency response email address,
                CLIENT should then immediately create a service desk ticket with
                a detailed explanation of the problem.
              </p>
              <h2>7.0 Backups</h2>
              <p>
                COMPANY performs regular (daily, weekly and monthly) backups, as
                required by HIPAA and US state-specific regulations. For more
                information on COMPANY&apos;s backup frequency, location and
                scope, please contact COMPANY directly.
              </p>
              <h2>8.0 Preventive Maintenance</h2>
              <p>
                Preventative maintenance, including both scalability,
                infrastructure and security upgrades are not to be counted as
                downtime and therefore receives no compensation. While most
                current systems provided by the COMPANY require little, or no,
                preventative maintenance - a program of Preventative Maintenance
                activities may be carried out by a COMPANY representative for
                the configured service.
              </p>
              <h2>9.0 Software Updates</h2>
              <p>
                As software updates become available, the COMPANY will make such
                updates available to the CLIENT at no additional charge for the
                duration of this SLA. Software updates may be released either as
                a stand-alone product pre-release, or as part of a consolidated
                software maintenance release package. Failure to pay for or
                facilitate the installation of applicable software maintenance
                within a reasonable timeframe will disqualify the CLIENT from
                seeking compensation for downtime for issues caused by unpatched
                software. This includes both software sold by COMPANY as well as
                relevant 3rd party software.
              </p>
              <h2>10.0 Scalability</h2>
              <p>
                COMPANY maintains a hosting environment which is designed to
                handle up to three (3) times the traffic of average daily
                traffic peaks (recorded over previous two (2) service months.
                CLIENT self-hosted environments are expected to maintain a
                similar degree of scalability. COMPANY will not be held
                responsible for outages that take place due to traffic spikes of
                three (3) times or more of average daily traffic peaks, but will
                make best efforts to handle such traffic spikes. Traffic spikes
                exceeding three (3) times of average daily traffic peaks may
                result in sustained downtime which will not count towards any
                service credits or refunds..
              </p>
              <h2>11.0 System Health Checks</h2>
              <p>
                The COMPANY carries out on CLIENT&apos;s behalf a program of
                system health checks on a scheduled basis. The primary purpose
                of these audits is to monitor the system performance and
                capacities and to notify the CLIENT of any current or impending
                issues.
              </p>
              <h2>12.0 Conflicting Terms</h2>
              <p>
                If any term of this SLA conflicts with terms of the requisite
                License or other service agreement to which this SLA relates,
                the terms of the requisite agreement shall prevail unless this
                SLA expressly states otherwise.
              </p>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default ServiceLevelAgreement;
